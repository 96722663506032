import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    connection: {
      isNetwork: true,
      isGqlPollSuccessful: true,
    },
    current: null,
    beamdose: 0,
    energy: 0,
    lifetime: null,
    bl04idGap: 0,
    bl05idGap: 0,
    bl06idGap: 0,
    bl04idStatus: 'unknown',
    bl05idStatus: 'unknown',
    bl06idStatus: 'unknown',
    bl04bmStatus: 'unknown',
    bl08bmStatus: 'unknown',
    bl09bmStatus: 'unknown',
    bl10bmStatus: 'unknown',
    nextInjectionsMessage: 'unknown',
    storageRingStatus: 'unknown',
    operationMode: 'unknown',
    operatorMessage: [],
  },
  mutations: {
    updateAttr(state, [name, data]) {
      state[name] = data;
    },
    setPollingSuccessStatus(state, status) {
      state.connection.isGqlPollSuccessful = status;
    },
    setNetworkStatus(state, status) {
      state.connection.isNetwork = status;
    },
  },
  actions: {
    updateAttr({ commit }, params) {
      commit('updateAttr', params);
    },
    setPollingSuccessStatus({ commit }, status) {
      commit('setPollingSuccessStatus', status);
    },
    setNetworkStatus({ commit }, status) {
      commit('setNetworkStatus', status);
    },
  },
  getters: {
    getConnectionState(state) {
      return state.connection.isNetwork && state.connection.isGqlPollSuccessful;
    },
    getCurrent(state) {
      if (state.current < 0) state.current = 0.00;
      return parseFloat(state.current).toFixed(2);
    },
    getBeamDose(state) {
      return parseFloat(state.beamdose).toFixed(2);
    },
    getEnergy(state) {
      if (state.energy > 1.50) state.energy = 1.50;
      return parseFloat(state.energy).toFixed(2);
    },
    getLifetime(state) {
      return parseFloat(state.lifetime).toFixed(2);
    },
    getbl04idGap(state) {
      return `${parseFloat(state.bl04idGap).toFixed(2)} mm`;
    },
    getbl05idGap(state) {
      return `${parseFloat(state.bl05idGap).toFixed(2)} mm`;
    },
    getbl06idGap(state) {
      return `${parseFloat(state.bl06idGap).toFixed(2)} mm`;
    },
    getbl04idStatus(state) {
      return state.bl04idStatus;
    },
    getbl04bmStatus(state) {
      return state.bl04bmStatus;
    },
    getbl05idStatus(state) {
      return state.bl05idStatus;
    },
    getbl06idStatus(state) {
      return state.bl06idStatus;
    },
    getbl08bmStatus(state) {
      return state.bl08bmStatus;
    },
    getbl09bmStatus(state) {
      return state.bl09bmStatus;
    },
    getbl10bmStatus(state) {
      return state.bl10bmStatus;
    },
    getNextInjectionsMessage(state) {
      return state.nextInjectionsMessage;
    },
    getStorageRingStatus(state) {
      return state.storageRingStatus;
    },
    getOperationMode(state) {
      return state.operationMode;
    },
    getOperatorMessage(state) {
      return state.operatorMessage;
    },
    getFormattedOperatorMessage(state) {
      return state.operatorMessage.map((message) => {
        const [date, time, ...msg] = message.split(' ');
        return { date, time, msg: msg.join(' ') };
      });
    },
  },
});
