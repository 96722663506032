import gql from 'graphql-tag';

export const AttributesMap = Object.freeze({
  current: '/current',
  beamdose: '/beamDose',
  energy: '/energy',
  lifetime: '/lifetime',
  bl04idGap: '/bl04idGap',
  bl05idGap: '/bl05idGap',
  bl06idGap: '/bl06idGap',
  bl04idStatus: '/bl04idOpenStatus',
  bl04bmStatus: '/bl04bmOpenStatus',
  bl05idStatus: '/bl05idOpenStatus',
  bl06idStatus: '/bl06idOpenStatus',
  bl08bmStatus: '/bl08bmOpenStatus',
  bl09bmStatus: '/bl09bmOpenStatus',
  bl10bmStatus: '/bl10bmOpenStatus',
  nextInjectionsMessage: '/nextInjectionsMessage',
  storageRingStatus: '/machineStatus',
  operationMode: '/userOperationStatus',
  operatorMessage: '/messageFromOperator',
});

export const GET_ATTR = gql`query ReadAttribute ($attr: [String]!){
              attributes(fullNames: $attr) {
                  name
                  value
              }
          }`;

export const DEVICE = 'web/facade/msp';

export const DATA_TIME_RANGE = 73;
